<template>
  <div>
    <el-form ref="resourceTagsForm" 
      data-testid="resource-tags">
      <el-form-item prop="resourceTags"
        :label-width="labelWidth">
        <template #label>
          {{ $t('services.resource-tags') }}
          <el-tooltip placement="top"
            :open-delay="500">
            <template #content>
              <p class="tooltip-p">
                {{ $t('services.resource-tags-info') }}
              </p>
            </template>
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <div :class="{'button-content': !productUid}">
          <span v-if="!productUid"
            :class="spanClass">{{ spanText }}</span>
          <el-button :class="{'full-width': productUid}"
            data-testid="manage-resource-tag-button"
            @click="openResourceTagsModal">
            {{ buttonText }}
          </el-button>
        </div>
        <manage-resource-tags-modal v-if="modalVisible"
          :resource-tags="tags"
          :visible.sync="modalVisible"
          :product-uid="productUid"
          @update:tags="handleResourceTagChange" />
      </el-form-item>
    </el-form>
    <!-- table will be displayed only when we're editing tags -->
    <div v-if="productUid" 
      class="mb-1"
      data-testid="resource-tags-list">
      <el-input v-model="search"
        class="search-input"
        size="medium"
        prefix-icon="el-icon-search"
        :placeholder="$t('services.search-tag-placeholder')"
        data-testid="resource-tag-search-input" />
      <el-table :data="filteredTags"
        stripe
        class="full-width"
        :empty-text="$t('services.empty-resource-tag-table')"
        data-testid="resource-tags-table">
        <el-table-column :label="$t('services.key')"
          prop="key" />
        <el-table-column :label="$t('services.value')"
          prop="value" />
      </el-table>
    </div>
  </div>
</template>

<script>
import ManageResourceTagsModal from './ManageResourceTagsModal.vue'
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'

export default {
  name: 'ResourceTags',
  components: {
    ManageResourceTagsModal,
  },
  props: {
    resourceTags: {
      type: Array,
      default: () => [],
    },
    labelWidth: {
      type: String,
      default: '250px',
    },
    productUid: {
      type: String,
      default: null,
    },
  },
  emits: ['update'],
  data() {
    return {
      modalVisible: false,
      search: '',
      tags: [...this.resourceTags],
    }
  },
  computed: {
    filteredTags() {
      if (!this.search) {
        return this.tags?.slice(0, 5)
      }

      const lowerCasedSearchTerm = this.search.toLocaleLowerCase()  
      return this.tags.filter(tag => tag.key.toLocaleLowerCase().includes(lowerCasedSearchTerm) || 
        tag.value.toLocaleLowerCase().includes(lowerCasedSearchTerm)).slice(0, 5)
    },
    buttonText() {
      return this.tags.length ? this.$t('services.manage-tags') : this.$t('services.add-tags')
    },
    spanClass() {
      return this.tags.length ? 'color-primary' : 'color-secondary'
    },
    spanText() {
      return this.tags.length ? this.$tc('services.tags-added-count', this.tags.length, { count: this.tags.length }) : this.$t('services.no-resource-tags')
    },
  },
  async mounted() {
    await this.initialiseTags()
  },
  methods: {
    async initialiseTags() {
      if (this.productUid) {
        await this.fetchResourceTags()
      } else {
        this.tags = [...this.resourceTags]
      }
    },
    handleResourceTagChange(updatedResourceTags) {
      this.tags = updatedResourceTags

      if (!this.productUid) {
        this.$emit('update', updatedResourceTags)
      }
    },
    async fetchResourceTags() {
      try {
        const res = await sdk.instance.product(this.productUid).getProductResourceTags()
        this.tags = res?.resourceTags || []
      } catch (error) {
        captureSentryError(error)
      }
    },
    openResourceTagsModal() {
      this.modalVisible = !this.modalVisible
      this.initialiseTags()
    },
  },
}
</script>

<style scoped>
::v-deep .el-table__empty-text {
  line-height: 50px;
}
.button-content {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  color: var(--color-text-primary);
  display: flex;
  justify-content: space-around;

  & > span {
    text-align: center;
    flex-grow: 1;
  }

  & > button {
    flex-grow: 1;
  }
}
.color-secondary {
  color: var(--color-text-secondary);
}
.color-primary {
  color: var(--color-primary);
}
</style>
