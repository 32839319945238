<template>
  <el-dialog id="manage-resource-tags-modal"
    :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    data-testid="manage-resource-tags-modal"
    top="4vh">
    <template #title>
      <div>
        <h3>{{ $t('services.manage-tags') }}</h3>
        <p class="resource-tags-info">
          {{ $t('services.resource-tags-info') }}
        </p>
      </div>
    </template>
    <!-- Form -->
    <el-form ref="resourceTagsForm"
      :model="resourceTagsData"
      :rules="resourceTagsRules"
      data-testid="resource-tag-form">
      <section v-if="resourceTagsData.tags.length">
        <div v-for="(tag, index) in resourceTagsData.tags"
          :key="index"
          class="flex-row-centered gap-1">
          <el-form-item :prop="`tags.${index}.key`"
            :label="index === 0 ? $t('services.key') : ''"
            class="flex-1 long-error"
            data-testid="key-form-item">
            <el-input v-model="tag.key"
              :data-demo="`tag-key-${index}-${Date.now()}`"
              data-testid="tag-key-input"
              clearable
              size="small" />
          </el-form-item>
          <el-form-item :prop="`tags.${index}.value`"
            :label="index === 0 ? $t('services.value') : ''"
            class="flex-1 long-error"
            data-testid="value-form-item">
            <el-input v-model="tag.value"
              :data-demo="`tag-value-${index}-${Date.now()}`"
              data-testid="tag-value-input"
              clearable
              size="small" />
          </el-form-item>
          <el-form-item class="actions">
            <el-tooltip placement="top"
              :content="$t('general.remove')"
              :open-delay="500">
              <i class="fas fa-trash-alt remove-tag-icon"
                @click="removeTag(index)" />
            </el-tooltip>
          </el-form-item>
        </div>
      </section>
      <section v-else>
        <div class="d-flex flex-justify-center gap-3">
          <p class="no-tag-desc">
            {{ $t('services.no-tag-desc') }}
          </p>
        </div>
      </section>
    </el-form>
    <div class="add-new-tag">
      <!-- Add Button -->
      <el-button data-testid="resource-tag-add-button"
        size="small"
        prefix-icon="el-icon-plus"
        type="success"
        :disabled="availableNumberOfTags === 0"
        @click="addTag">
        {{ $t('services.add-new-tag') }}
      </el-button>
      <small>{{ maxLimitDisplayText }}</small>
    </div>
    <template #footer>
      <!-- Action Buttons -->
      <div class="text-align-right">
        <!-- Cancel Button -->
        <el-button data-testid="resource-tag-cancel-button"
          @click="cancel">
          {{ $t('general.cancel') }}
        </el-button>
        <!-- Save Button -->
        <el-button type="primary"
          data-testid="resource-tag-save-button"
          @click="submitForm">
          {{ $t('general.save') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
/**
 * This modal appears during the creation of services, allowing customers to
 * add tags to their resources. This functionality enables customers to
 * categorize resources for more effective management.
 */
export default {
  name: 'ManageResourceTagsModal',

  props: {
    resourceTags: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    productUid: {
      type: String,
      default: null,
    },
  },
  emits: ['update:visible', 'update:tags'],
  data() {
    return {
      resourceTagsData: {
        tags: this.resourceTags
          ? this.resourceTags.map(tag => ({ key: tag.key, value: tag.value }))
          : [],
      },
      tagsLimit: 50,
    }
  },
  computed: {
    availableNumberOfTags() {
      return this.resourceTagsData.tags.length >= this.tagsLimit
        ? 0
        : this.tagsLimit - this.resourceTagsData.tags.length
    },
    maxLimitDisplayText() {
      return this.availableNumberOfTags !== 0
        ? this.$tc('services.tags-max-limit', this.availableNumberOfTags, {
          count: this.availableNumberOfTags,
        })
        : this.$t('services.tag-exceeded-limit')
    },
    resourceTagsRules() {
      const rules = {}
      this.resourceTagsData.tags.forEach((tag, index) => {
        rules[`tags.${index}.key`] = [
          {
            required: true,
            message: this.$t('validations.required', {
              thing: this.$t('services.key'),
            }),
            trigger: ['blur', 'change'],
          },
          { validator: this.validateKey, trigger: ['blur', 'change'] },
        ]
        rules[`tags.${index}.value`] = [
          {
            required: true,
            message: this.$t('validations.required', {
              thing: this.$t('services.value'),
            }),
            trigger: ['blur', 'change'],
          },
          { validator: this.validateValue, trigger: ['blur', 'change'] },
        ]
      })
      return rules
    },
  },
  methods: {
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * This function checks if the provided `key` matches the allowed pattern, which consists of 
     * lowercase alphanumeric characters and specific special characters (`._-:\\/`).
     * 
     * If the `key` is invalid, it triggers a callback with an error message.
     * If the `key` is valid, it calls the callback with no arguments to indicate success.
     * 
     * @param {Object} rule - The validation rule object.
     * @param {string} value - The tag key to be validated.
     * @param {Function} callback - The callback function to be called with the validation result.
     * @returns {void} This function does not return a value.
     */
    validateKey(rule, value, callback) {
      const regex = /^[a-z0-9_.\-:\\/]+$/
      const KeyIsDuplicated = this.resourceTagsData.tags.filter(tag => tag.key === value).length > 1
      if (!regex.test(value)) {
        callback(new Error(this.$t('services.invalid-tag-key')))
      } else if (KeyIsDuplicated) {
        callback(new Error(this.$t('services.duplicate-key-error')))
      } else {
        callback() // Valid case
      }
    },
    /**
     * This function checks if the provided `value` matches the allowed pattern, which consists of 
     * alphanumeric characters and specific special characters (`._-:;\\/@+`).
     * 
     * If the `value` is invalid, it triggers a callback with an error message.
     * If the `value` is valid, it calls the callback with no arguments to indicate success.
     * 
     * @param {Object} rule - The validation rule object
     * @param {string} value - The tag value to be validated.
     * @param {Function} callback - The callback function to be called with the validation result.
     * @returns {void}
     */
    validateValue(rule, value, callback) {
      const regex = /^[a-zA-Z0-9_.\-:;\\/@+ ]+$/ // Alphanumeric, space, and specific special characters
      if (!regex.test(value)) {
        callback(new Error(this.$t('services.invalid-tag-value')))
      } else {
        callback() // Valid case
      }
    },
    /**
     * This function checks if the available number of tags (`availableNumberOfTags`) is greater than 0
     * before adding a new tag. If there are available slots, it appends an object with empty `key` and `value`
     * properties to the `resourceTagsData.tags` array.
     *
     * @returns {void}
     */
    addTag() {
      if (this.availableNumberOfTags > 0) {
        this.resourceTagsData.tags.push({
          key: '',
          value: '',
        })
      }
    },
    /**
     * This function deletes a single tag from the `tags` array by using the provided index.
     *
     * @param {number} tagIndex - The index of the tag to be removed from the `tags` array.
     * @returns {void}
     */
    removeTag(tagIndex) {
      this.resourceTagsData.tags.splice(tagIndex, 1)
    },
    /**
     * This function performs the following steps:
     * 1. Validates the `resourceTagsForm` form using the validation mechanism provided by `$refs`.
     * 2. If the form is valid:
     *    - If `productUid` is present, it updates the resource tags for the specified product using `updateResourceTags` API.
     *    - Emits an `update:tags` event with the updated tags data.
     *    - Hides the form by calling `setVisible(false)`.
     * 3. If the form is not valid, it shows a notification with a validation error message.
     *
     * @returns {void} This function does not return a value.
     */
    submitForm() {
      this.$refs.resourceTagsForm.validate(async isValid => {
        if (isValid) {
          try {
            if (this.productUid) {
              // when product id is present, we're editing the tags
              await sdk.instance
                .product(this.productUid)
                .updateResourceTags(this.resourceTagsData.tags)
            }
            this.$emit('update:tags', this.resourceTagsData.tags)
            this.setVisible(false)
          } catch (error) {
            captureSentryError(error)
            this.$notify({
              title: this.$t('general.request-failed'),
              type: 'error',
              duration: 3000,
            })
          }
        } else {
          this.$notify({
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          })
        }
      })
    },
    /**
     * Cancels the form editing and resets the form fields.
     * 
     * @returns {void}
     */
    cancel() {
      this.$refs.resourceTagsForm.resetFields()
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-form-item {
    margin-bottom: 15px;

    &:last-child {
      &__label {
        visibility: hidden;
      }
    }
  }

  .flex-row-centered:first-child {
    .actions {
      margin-top: 3.5rem;
    }
  }
}

.resource-tags-info {
  line-height: normal;
}

.no-tag-desc {
  font-weight: 500;
}

.add-new-tag {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .el-button {
    align-self: flex-start;
    width: auto;
  }
}

.remove-tag-icon {
  color: var(--color-danger);;
  cursor: pointer;
}
</style>
